<template>
	<div class="p-grid">
		<div class="p-col-12 no-print">
			<h1>Générateur de poster
				<small style="font-size: .7rem"><a target="_blank"
												   rel="noopener norefferer"
												   href="https://fr.wikipedia.org/wiki/Code_QR"
				>Qu'est-ce qu'un QR-code ?</a></small></h1>
			<p class="text-muted">Utilisez cette page pour générer une page imprimable disposant d'un QR-Code,
				avec ou sans campagne, que vous pourrez afficher.<br>
				Vous pouvez imprimer directement l'image en cliquant sur le bouton "imprimer".</p>
		</div>

		<div class="p-col-12">
			<div class="card print-page-100vh p-d-flex p-jc-center p-align-center">
				<div class="p-grid p-nogutter ">
					<div class="p-col-12 p-mb-6 no-print" style="width: 100%">
						<div class="p-fluid">
							<CampaignSelector :value="eventGuid"
											  @raw="eventData = $event"
											  @input="eventGuid = $event"></CampaignSelector>
						</div>
					</div>

					<div v-if="urlQrCode !== undefined" class="p-col-12 p-d-flex p-jc-center p-mt-4">
						<div style="position: relative; min-width: 300px;" class="p-fluid p-qrcode">
							<div class="canva-qr">
								<QrcodeVue :size="size" level="H" :value="urlQrCodeComputed"></QrcodeVue>
							</div>

							<div class="p-text-center print-text-bigger p-mt-5">
								<h5>{{ name }}</h5>
								<p v-if="city !== ''">
									{{ location }}<br>
									{{ street }}<br>
									{{ postalCode }} {{ city }}
								</p>
								<small v-if="dates !== ''">{{ dates }}</small>
							</div>
						</div>
					</div>

					<div class="p-fluid print-footer print-only">
						<div class="footer-content p-d-flex p-jc-center p-align-center">
							<span class="footer-text" style="margin-right: 10px">by Freecard</span>
							<img src="/assets/images/icons/logo-freecard.svg" alt="freecard" width="30"/>
						</div>
					</div>


					<div class="p-col-12 p-mt-6 no-print" style="width: 100%">
						<div class="p-fluid">
							<Button label="Imprimer" icon="pi pi-save"
									@click="printPage" class="b-button p-m-2"></Button>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import userMixin from "../../mixins/userMixin";
	/**
	 * @see https://www.npmjs.com/package/qrcode.vue
	 */
	import QrcodeVue from 'qrcode.vue'

	import CampaignSelector from '../../components/app/crm/CampaignSelector.vue';
	import calendarMixin from "../../mixins/calendarMixin";

export default {
	data(){
		return {
			urlQrCode: undefined,
			size: 800,
			eventGuid: undefined,
			eventData: undefined,
		}
	},
	mounted(){
		this.waitUser();
	},
	methods: {
		printPage(){
			window.print();
		},
		waitUser(){
			const vm = this;
			if(vm.userData === undefined){
				setTimeout(() => {
					vm.waitUser();
				}, 100);
				return;
			}
			vm.urlQrCode = window.appData.env.profileBaseUrl.replace('{id}', vm.userData.id).replace('{key}', vm.userData.guid.substring(0,10));
		},
	},
	computed: {
		...mapState({
			userData: 'user',
		}),
		urlQrCodeComputed(){
			if(this.eventGuid !== undefined){
				return this.urlQrCode + '?utm_source=' + this.eventGuid;
			}
			return this.urlQrCode;
		},
		name(){
			if(this.eventData !== undefined && this.eventData.e !== undefined){
				return this.eventData.e.name;
			} else {
				return '';
			}
		},
		location(){
			if(this.eventData !== undefined && this.eventData.e !== undefined){
				return this.eventData.e.onSiteLocation;
			} else {
				return '';
			}
		},
		street(){
			if(this.eventData !== undefined && this.eventData.e !== undefined){
				return this.eventData.e.addrStreet;
			} else {
				return '';
			}
		},
		city(){
			if(this.eventData !== undefined && this.eventData.e !== undefined){
				return this.eventData.e.addrCity;
			} else {
				return '';
			}
		},
		postalCode(){
			if(this.eventData !== undefined && this.eventData.e !== undefined){
				return this.eventData.e.addrPostalCode;
			} else {
				return '';
			}
		},
		dates(){
			if(this.eventData !== undefined && this.eventData.e !== undefined && this.eventData.e.dateStart !== null){
				return this.tmsRangeToHumanRange(this.eventData.e.dateStart,this.eventData.e.dateEnd);
			} else {
				return '';
			}

		}

	},
	mixins: [userMixin, calendarMixin],
	components: {
		QrcodeVue,
		CampaignSelector,
	}
}
</script>

<style lang="scss" scoped>

	.p-qrcode {
		canvas {
			height: auto!important;
			width: 25%!important;
			display: block;
			margin: auto;
		}
	}
</style>
